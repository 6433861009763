<div class="row">

    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                Status
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <dl>
                        <dt class="col-sm-3">Containers</dt>
                        <dd class="col-sm-9">{{ report?.totalContainers || '-'}}</dd>
                        <dt class="col-sm-3">Devices</dt>
                        <dd class="col-sm-9">{{ report?.totalDevices || '-'}}</dd>
                        <dt class="col-sm-3">Unconfigured Devices</dt>
                        <dd class="col-sm-9">{{ report?.totalUnconfiguredDevices || '-'}}</dd>
                        <dt class="col-sm-3">Queued for Configuration</dt>
                        <dd class="col-sm-9">{{ queueStatus.queuedItems || '-' }}</dd>                        
                    </dl>
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="queueStatus.errors.length > 0"  class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Errors in Configuration
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm table-hover">
                            <thead class="thead-light">
                                <tr>
                                    <th>Id</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let queueError of queueStatus.errors">
                                    <td>{{queueError.id}}</td>
                                    <td>{{queueError.message}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="alert alert-warning" role="alert">
                        At least one Bintel device cannot be configured in the Bintel system. Manually fix the error above and reset the device to 'Queued' in [Bintel].[dbo].[Bintel_SerialNumber] to allow a new automatic reconfiguration attempt.    
                    </div>
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="unconfiguredDevices.length > 0"  class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Unconfigured Devices
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm table-hover">
                            <thead class="thead-light">
                                <tr>
                                    <th>Id</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let deviceId of unconfiguredDevices">
                                    <td>{{deviceId}}</td>
                                    <!-- <td><button (click)="configure(deviceId)" class="btn btn-primary btn-xs">Configure</button></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="containersWithoutDevice.length > 0" class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Containers Without Device
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Container</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let container of containersWithoutDevice">
                                <td>
                                    <a href="https://insights.bintel.se/containers/{{container.id}}" target="_blank">
                                        {{container.name}}
                                    </a>
                                </td>
                                <td>
                                    <button (click)="deleteContainer(container.id)" class="btn btn-danger">
                                        Delete
                                    </button>
                                </td>                                
                            </tr>
                        </table>
                    </div>
                    <div class="alert alert-warning" role="alert">
                        SiteInfo/Bintel integration should create one container per Bintel device. There is at least one container in Bintel without assigned device. If this orphaned container has been created by the integration then remove the container and reconfigure the Bintel device again.    
                    </div>                    
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="devicesAssignedMultipleTimes.length > 0" class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Devices assigned multiple times
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Id</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tr *ngFor="let device of devicesAssignedMultipleTimes">
                                <td>{{device.id}}</td>
                                <td>{{device.count}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="alert alert-warning" role="alert">
                        At least one Bintel device has been assigned to multiple containers. The SiteInfo/Bintel integration should only create one container per Bintel device. This could possibly lead to duplicate data beeing sent to SiteInfo. Investigate in the Bintel portal.    
                    </div>
                </spinner>
            </div>
        </div>
    </div>




</div>