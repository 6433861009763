

export class BintelMultipleDeviceReportRow {
    id: string;
    count: number;
}

export class BintelContainerWithoutDeviceReportRow {
    id: string;
    name: string;
}

export class BintelQueueError {
    id: string;
    message: string;
}

export class BintelQueueStatus {
    queuedItems: number;
    errors: BintelQueueError[]
}

export class BintelIntegrationStatusReport {

    totalContainers: number;
    totalDevices: number;
    totalUnconfiguredDevices: number;
    containersWithoutDevice: BintelContainerWithoutDeviceReportRow[];
    devicesAssignedMultipleTimes: BintelMultipleDeviceReportRow[];
    unconfiguredDevices: string[];
    queueStatus: BintelQueueStatus;
}
